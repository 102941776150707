import css from './Select.module.scss'
import React, { MutableRefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { IMapSeat } from 'meta/map'
import { ICheckoutDetails, PaymentMethod, TicketSelectionType } from 'meta/pages/checkout'
import { ApiEndpoint } from 'controller/endpoint'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { AnalyticsEventType, trackFbPixelEvent } from 'utils/analytics/track'
import { getPrObject, updatePrObject } from 'utils/cookie/client'
import { Dates } from 'utils/dates'

import EventCollection from 'components/Checkout/EventCollection'
import EventDetails from 'components/Checkout/EventDetails'
import NoTickets from 'components/Checkout/NoTickets'
import PaymentMethodDialogError from 'components/Checkout/PaymentMethodDialogError'
import PaymentMethods from 'components/Checkout/PaymentMethods'
import RecapBox from 'components/Checkout/RecapBox'
import Season from 'components/Checkout/Season'
import TicketSelection from 'components/Checkout/TicketSelection'
import TitleBar from 'components/Checkout/TitleBar'
import {
  useCheckoutCartTickets,
  useCheckoutDetails,
  useCheckoutEventCodeUrl,
  useCheckoutPayment,
  useCheckoutTicketSelection,
  useCheckoutTypeTicketCollectionId,
} from 'hooks/useCheckout'
import { useFacebookPixelId, useGoogleAnalyticsId, usePrCookieTime, useS2SToken } from 'hooks/useEnv'

interface IProps {
  modalRef?: MutableRefObject<HTMLElement>
}

const Select: React.FC<IProps> = (props) => {
  const { modalRef } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    eventDetails,
    typeTicketCollection,
    eventCollection,
    eventTicket,
    insideIFrame,
    initialTypeTicketId,
    metadata,
  } = useCheckoutDetails() as ICheckoutDetails
  const ticketSelection = useCheckoutTicketSelection()
  const cartTickets = useCheckoutCartTickets()
  const payment = useCheckoutPayment()
  const prCookieTime = parseInt(usePrCookieTime())
  const [stateVoucherError, setStateVoucherError] = useState<boolean>(false)
  const saleAvailable: boolean = eventDetails
    ? Dates.isSameOrBefore(new Date(eventDetails.dateStartSellTicket), new Date())
    : true
  const isCollectionPopulated = eventCollection && eventCollection.list && eventCollection.list.length > 0
  const showCloseButton = !(insideIFrame && isCollectionPopulated)
  const googleAnalytics4Id = useGoogleAnalyticsId()
  const ticketsmsFbPixelId = useFacebookPixelId()

  const typeTicketCollectionId = useCheckoutTypeTicketCollectionId()
  const codeUrl = useCheckoutEventCodeUrl()

  const s2sToken = useS2SToken()

  const selectSeat = (seats: Array<IMapSeat>, typeTicketId?: number) => {
    const cartTotal = cartTickets?.reduce<number>((total, { quantity }) => total + quantity, 0) ?? 0

    const isStateVoucherPMActive =
      payment &&
      payment?.methodCurrent &&
      [PaymentMethod.diciottoApp, PaymentMethod.cartaMeritoCultura].includes(payment.methodCurrent)

    if (isStateVoucherPMActive && cartTotal >= 1) {
      setStateVoucherError(true)
      return
    } else {
      seats.forEach((seat) => {
        if (codeUrl && typeTicketCollectionId) {
          dispatch(
            CheckoutActions.selectSeatForSeasonEvent({
              codeUrl: codeUrl,
              collectionId: typeTicketCollectionId,
              seat,
              typeTicketId,
            })
          )
        }
        if (eventDetails) {
          dispatch(
            CheckoutActions.selectSeat({ codeUrl: eventDetails.codeUrl, eventId: eventDetails.id, seat, typeTicketId })
          )
        }
      })
    }
  }

  const titleKey =
    ticketSelection?.type === TicketSelectionType.automatic ? 'checkout:select_tickets' : 'checkout:select_seats'

  useEffect(() => {
    if (initialTypeTicketId && ticketSelection?.type === TicketSelectionType.automatic) {
      dispatch(CheckoutActions.addToCart({ id: initialTypeTicketId }))
    }
  }, [dispatch, initialTypeTicketId, ticketSelection])

  useEffect(() => {
    if (eventDetails) {
      const { codeUrl } = eventDetails
      const { parentCodeUrl } = metadata

      if (parentCodeUrl && parentCodeUrl !== codeUrl) {
        const prObj = getPrObject()

        prObj[parentCodeUrl] = codeUrl

        delete prObj[codeUrl]

        updatePrObject(prObj, prCookieTime)
      }
    }
  })

  useEffect(() => {
    if (eventDetails) {
      const { codeUrl } = eventDetails

      const prObj = getPrObject()

      if (prObj[codeUrl] && prObj[codeUrl].toLowerCase() !== codeUrl.toLowerCase()) {
        dispatch(CheckoutActions.init({ codeUrl: prObj[codeUrl], insideIFrame }))
      }
    }
  }, [dispatch, eventDetails, insideIFrame])

  useEffect(() => {
    const orgFbPixelId = typeTicketCollection?.fbPixel ?? eventDetails?.fbPixel
    const isPixelFromOrganizer = orgFbPixelId !== ticketsmsFbPixelId

    //@ts-expect-error ttq might not be defined
    if (window && window.fbq && orgFbPixelId) {
      //@ts-expect-error ttq might not be defined
      window.fbq('init', orgFbPixelId)
    }

    trackFbPixelEvent(
      AnalyticsEventType.InitiateCheckout,
      {},
      {
        route: ApiEndpoint.analytics.event(typeTicketCollection?.id ?? eventDetails?.codeUrl),
        fromOrganizer: isPixelFromOrganizer,
        s2sToken: s2sToken,
      }
    )

    if (typeTicketCollection?.id || eventDetails?.id) {
      import('react-ga4')
        .then((module) => module.default)
        .then((ReactGA) => {
          ReactGA.initialize(googleAnalytics4Id)
          ReactGA.event('begin_checkout', {
            currency: 'EUR',
            value: 1,
            event_label: typeTicketCollection?.id ?? eventDetails?.id,
            items: [
              {
                item_id: typeTicketCollection?.id ?? eventDetails?.id,
                item_name: typeTicketCollection?.name ?? eventDetails?.name,
              },
            ],
          })
        })
    }

    //@ts-expect-error ttq might not be defined
    if (window && window.ttq) {
      //@ts-expect-error ttq might not be defined
      window.ttq.track('InitiateCheckout')
    }
  }, [])

  return (
    <div className={css.checkout_steps_select_container}>
      {(!insideIFrame || (insideIFrame && isCollectionPopulated)) && <EventDetails showCloseButton={showCloseButton} />}

      {typeTicketCollection && <Season />}

      {eventTicket && saleAvailable ? (
        <div className={css.select_body}>
          <EventCollection />

          <TitleBar title={String(t(titleKey))} />

          <TicketSelection modalRef={modalRef} onSelectSeat={selectSeat} />

          <PaymentMethods />

          <RecapBox />

          <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
            {<div className={css.firefox_whitespace_workaround} />}
          </MediaQuery>
        </div>
      ) : (
        <NoTickets messageKey={saleAvailable ? 'common:notickets_available' : 'checkout:sale_not_yet_available'} />
      )}

      {stateVoucherError && (
        <PaymentMethodDialogError
          paymentLabel={
            payment?.methodCurrent === PaymentMethod.diciottoApp
              ? t('checkout:diciotto_app')
              : t('checkout:carta_merito_cultura')
          }
          messageKey="checkout:error_18app_change_payment"
          onClose={() => setStateVoucherError(false)}
        />
      )}
    </div>
  )
}

export default Select
