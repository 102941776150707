import css from './Item.module.scss'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Dates } from 'utils/dates'

import { useUrlCloudfront } from 'hooks/useEnv'

interface Props {
  coverUrl?: string
  title: string
  date: Date
}

const Item: React.FC<Props> = (props) => {
  const { coverUrl, title, date } = props

  const { t } = useTranslation()

  const urlCloudfront = useUrlCloudfront()
  const imgUrl = coverUrl ?? `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.png`

  const formattedDate = useMemo<string>(() => Dates.format(date, t('dates:common:ticket')), [date, t])
  const formattedTime = useMemo<string>(() => Dates.format(date, t('dates:common:eventTime')), [date, t])

  return (
    <div className={css.checkout_season_item_container}>
      <div className={css.coverBox}>
        <img src={imgUrl} />
      </div>
      <div className={css.textBox}>
        <div className={css.title}>{title}</div>
        <div className={css.date}>
          <span className={css.day}>{formattedDate}</span>
          <span className={css.slash}>{` / `}</span>
          <span className={css.time}>{formattedTime}</span>
        </div>
      </div>
    </div>
  )
}

export default Item
