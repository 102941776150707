import css from './Season.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'
import { ICheckoutSeasonItem } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useCheckoutEventCodeUrl, useCheckoutSeasonEvents, useCheckoutTypeTicketCollectionId } from 'hooks/useCheckout'

import Items from './Items/Items'
import ItemsSkeleton from './ItemsSkeleton/ItemsSkeleton'

const Season: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const events = useCheckoutSeasonEvents()

  const codeUrl = useCheckoutEventCodeUrl()

  const typeTicketCollectionId = useCheckoutTypeTicketCollectionId()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [data, setData] = useState<ICheckoutSeasonItem[]>([])

  useEffect(() => {
    if (typeTicketCollectionId && codeUrl) {
      dispatch(CheckoutActions.fetchSeasonEvents({ codeUrl, typeTicketCollectionId }))
    }
  }, [typeTicketCollectionId, codeUrl])

  useEffect(() => {
    if (events && events.length > 0) {
      const mappedEvents: ICheckoutSeasonItem[] = events.map((elem) => {
        return {
          coverUrl: elem.coverImages[0]?.url,
          date: new Date(elem.dateEvent_tz),
          title: elem.name,
        }
      })
      setData(mappedEvents)
    }
  }, [events])

  return (
    <GridContainer>
      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={{ [Breakpoints.laptop]: 16, [Breakpoints.mobile]: 17 }}
        >
          <div className={css.checkout_season_container}>
            <div className={css.dropdown}>
              <h3 className={css.header}>{t('checkout:season_dropdown_header')}</h3>
              <div onClick={() => setIsOpen(!isOpen)} className={classNames(css.arrow, { [css.isOpen]: isOpen })}>
                {!isOpen && <Img src="/arrows/black-down.svg" />}
                {isOpen && <Img src="/arrows/white-up.svg" />}
              </div>
            </div>
          </div>

          {isOpen && data && data?.length > 0 && <Items data={data} />}

          {isOpen && data && data?.length == 0 && <ItemsSkeleton />}
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default Season
