import css from './Automatic.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Range } from 'react-input-range'

import { ICheckoutDetails } from 'meta/pages/checkout'
import { ApiEndpoint } from 'controller/endpoint'
import { AnalyticsEventType, trackFbPixelEvent } from 'utils/analytics/track'

import Ticket from 'components/Checkout/TicketSelection/Automatic/Ticket'
import ShowMoreBox from 'components/Structure/ShowMoreBox'
import { useCheckoutCartTickets, useCheckoutDetails } from 'hooks/useCheckout'
import { useS2SToken } from 'hooks/useEnv'
import { useFacebookPixelId, useGoogleAnalyticsId } from 'hooks/useEnv'

const INTIALLY_SHOWN_TICKETS = 4

interface IProps {
  priceRangeCurrent: Range
}

const Automatic: React.FC<IProps> = (props) => {
  const { priceRangeCurrent } = props

  const { t } = useTranslation()
  const { eventTicket, initialTypeTicketId, eventDetails, typeTicketCollection } =
    useCheckoutDetails() as ICheckoutDetails
  const cartTickets = useCheckoutCartTickets()

  const [showAll, setShowAll] = useState<boolean>(false)
  const [addNum, setAddNum] = useState<number>(0)
  const hasShowMoreBeenToggledYet = useRef<boolean>(false)

  const googleAnalytics4Id = useGoogleAnalyticsId()
  const ticketsmsFbPixelId = useFacebookPixelId()

  const s2sToken = useS2SToken()

  const filteredTickets = eventTicket.data.filter((x) => {
    if (!hasShowMoreBeenToggledYet.current && initialTypeTicketId) {
      return x.id === initialTypeTicketId
    }

    return x.priceNumber >= priceRangeCurrent.min && x.priceNumber <= priceRangeCurrent.max
  })

  if (!hasShowMoreBeenToggledYet.current && initialTypeTicketId && eventTicket.data.length > 1) {
    const halfTicket = eventTicket.data.find((x) => {
      return x.id !== initialTypeTicketId
    })
    if (halfTicket) filteredTickets.push(halfTicket)
  }

  const moreThanShowable = filteredTickets.length > INTIALLY_SHOWN_TICKETS
  const ticketsInitialSlice = filteredTickets.slice(0, INTIALLY_SHOWN_TICKETS)

  const ticketsToShow = !moreThanShowable || showAll ? filteredTickets : ticketsInitialSlice
  const unfilteredTickets = ticketsToShow.filter((x) => x.show === true)

  const toggleShowAll = () => {
    hasShowMoreBeenToggledYet.current = true
    setShowAll((prev) => !prev)
  }

  const fbPixelCallback = (typeTicketId: number, removeFromCart: boolean = false) => {
    //@ts-expect-error fbq might not be defined
    if (window && window.fbq && (eventDetails || typeTicketCollection)) {
      const orgFbPixelId = typeTicketCollection?.fbPixel ?? eventDetails?.fbPixel
      const isPixelFromOrganizer = orgFbPixelId !== ticketsmsFbPixelId

      //@ts-expect-error fbq might not be defined
      orgFbPixelId && window.fbq('init', orgFbPixelId)

      setAddNum((addNum) => addNum + 1)

      let totalAmount: number = 0

      cartTickets?.forEach((elem) => {
        const ticketPrice = eventTicket.data.find((x) => x.id === elem.typeTicketId)?.priceNumber ?? 0
        totalAmount += elem.quantity * ticketPrice
      })

      if (removeFromCart) {
        const ticketPrice = eventTicket.data.find((x) => x.id === typeTicketId)?.priceNumber ?? 0

        const cartData = {
          'content_ids': [typeTicketId],
          'content_name': addNum,
          'content_type': 'product',
          'contents': [
            {
              id: typeTicketId,
              quantity: 1,
            },
          ],
          'currency': 'EUR',
          'value': ticketPrice | 0,
        }

        trackFbPixelEvent(AnalyticsEventType.RemoveFromCart, cartData, {
          route: ApiEndpoint.analytics.event(typeTicketCollection?.id ?? eventDetails?.codeUrl),
          fromOrganizer: isPixelFromOrganizer,
          s2sToken: s2sToken,
        })
      } else {
        const cartData = {
          'content_ids': [typeTicketCollection?.id ?? eventDetails?.id],
          'content_name': addNum,
          'content_type': 'product',
          'contents': cartTickets?.map((elem) => {
            return {
              id: elem.typeTicketId,
              quantity: elem.quantity,
            }
          }),
          'currency': 'EUR',
          'value': totalAmount.toFixed(2),
        }

        trackFbPixelEvent(AnalyticsEventType.AddToCart, cartData, {
          route: ApiEndpoint.analytics.event(typeTicketCollection?.id ?? eventDetails?.codeUrl),
          fromOrganizer: isPixelFromOrganizer,
          s2sToken: s2sToken,
        })
      }
    }
  }

  const getCartDataForGA4 = (typeTicketId: number, removeFromCart: boolean = false) => {
    let cartData = {}

    setAddNum((addNum) => addNum + 1)

    let totalAmount: number = 0

    cartTickets?.forEach((elem) => {
      const ticketPrice = eventTicket.data.find((x) => x.id === elem.typeTicketId)?.priceNumber ?? 0
      const ticketPresale = eventTicket.data.find((x) => x.id === typeTicketId)?.presaleNumber ?? 0

      totalAmount += elem.quantity * (ticketPrice + ticketPresale)
    })

    if (eventDetails) {
      if (removeFromCart) {
        const ticketPrice = eventTicket.data.find((x) => x.id === typeTicketId)?.priceNumber

        cartData = {
          'currency': 'EUR',
          'value': ticketPrice ? ticketPrice.toFixed(2) : 0,
          'event_label': eventDetails.id,
          'items': [
            {
              item_id: typeTicketId,
              item_name: eventDetails.name,
              price: ticketPrice ? ticketPrice.toFixed(2) : 0,
              quantity: 1,
            },
          ],
        }
      } else {
        cartData = {
          'currency': 'EUR',
          'value': totalAmount.toFixed(2),
          'event_label': eventDetails.id,
          'items': cartTickets?.map((elem) => {
            return {
              item_id: elem.typeTicketId,
              item_name: eventDetails?.name,
              quantity: elem.quantity,
            }
          }),
        }
      }

      return cartData
    }
  }

  const getCartDataForTikTok = (typeTicketId: number, removeFromCart: boolean = false) => {
    let cartData = {}

    let totalAmount: number = 0

    cartTickets?.forEach((elem) => {
      const ticketPrice = eventTicket.data.find((x) => x.id === elem.typeTicketId)?.priceNumber ?? 0
      const ticketPresale = eventTicket.data.find((x) => x.id === typeTicketId)?.presaleNumber ?? 0

      totalAmount += elem.quantity * (ticketPrice + ticketPresale)
    })

    if (removeFromCart) {
      const ticketPrice = eventTicket.data.find((x) => x.id === typeTicketId)?.priceNumber

      // TODO implement stuff here
      cartData = {
        content_id: typeTicketId,
        value: ticketPrice,
        content_type: 'product',
      }
    } else {
      cartData = {
        price: totalAmount.toFixed(2),
        value: totalAmount.toFixed(2),
        currency: 'EUR',
        content_type: 'product_group',
        contents: cartTickets?.map((elem) => {
          return {
            content_id: elem.typeTicketId,
            quantity: elem.quantity,
          }
        }),
      }
    }

    return cartData
  }

  const ga4Callback = (typeTicketId: number, removeFromCart: boolean = false) => {
    import('react-ga4')
      .then((module) => module.default)
      .then((ReactGA) => {
        ReactGA.initialize(googleAnalytics4Id)

        const cartData = getCartDataForGA4(typeTicketId, removeFromCart)

        if (removeFromCart) {
          if (eventDetails) {
            ReactGA.event('remove_from_cart', cartData)
          }
        } else {
          ReactGA.event('add_to_cart', cartData)
        }
      })
  }

  const tiktokPixelCallback = (typeTicketId: number, removeFromCart: boolean = false) => {
    //@ts-expect-error ttq might not be defined
    if (window && window.ttq) {
      const cartData = getCartDataForTikTok(typeTicketId, removeFromCart)

      if (removeFromCart) {
        // TODO ADD REMOVE FROM CART TIKTOK EVENT
      } else {
        //@ts-expect-error ttq might not be defined
        window.ttq.track('AddToCart', cartData)
      }
    }
  }

  const analyticsCallback = (typeTicketId: number, removeFromCart: boolean = false) => {
    fbPixelCallback(typeTicketId, removeFromCart)
    ga4Callback(typeTicketId, removeFromCart)
    tiktokPixelCallback(typeTicketId, removeFromCart)
  }

  useEffect(() => {
    if (initialTypeTicketId) {
      fbPixelCallback(initialTypeTicketId)
      ga4Callback(initialTypeTicketId)
      tiktokPixelCallback(initialTypeTicketId)
    }
  }, [])

  return (
    <div className={css.checkout_ticketselection_automatic_container}>
      {ticketsToShow.map((elem, key) => {
        return <Ticket key={key} data={elem} analyticsCallback={analyticsCallback} />
      })}
      {((!hasShowMoreBeenToggledYet.current && initialTypeTicketId) || moreThanShowable) &&
        eventTicket.data.length > 1 && (
          <ShowMoreBox
            toggleCallback={toggleShowAll}
            showMoreText={String(t('checkout:show_all'))}
            showLessText={String(t('checkout:show_less'))}
            showAll={showAll}
            noGradient={unfilteredTickets.length === 1}
          />
        )}
    </div>
  )
}

export default Automatic
